// types
import type { Review } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

// nuxt
import { useNuxtApp } from '#app'

export const useReviewStore = defineStore('review', () => {
  // fetch repositories
  const { $reviews } = useNuxtApp()

  // state
  const reviews = ref<Review[]>([])

  // actions
  async function index (config?: NitroFetchOptions<string>) {
    const { reviews: _reviews } =
      await $reviews.index<{ reviews: Review[] }>(config)
    reviews.value = _reviews || []
    return reviews
  }

  async function update (
    id: number | string,
    payload?: Record<string, any>,
    config?: NitroFetchOptions<string>
  ) {
    const { review: _review } =
      await $reviews.update<{ review: Review }>(id, { review: payload }, config)
    if (reviews.value.length) {
      reviews.value = reviews.value.filter((stateReview) => {
        return stateReview.id !== _review?.id
      })
      if (_review) {
        reviews.value = [...reviews.value, _review]
      }
    }
    return _review
  }

  return {
    index,
    reviews,
    update
  }
})
