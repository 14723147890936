<script setup lang="ts">
// types
import type { Nullable, Student } from '@revolutionprep/types'
import type { FetchError } from 'ofetch'

// vuetify
import { useDisplay, useTheme } from 'vuetify'

// stores
import { useCourseMaterialsStore } from '@/store/course-materials'
import { useGlobalStore } from '@/store/global'
import { useReviewStore } from '@/store/review'
import { useTrialStore } from '@/store/trial'

// composables
import { useMenuItems } from '~/composables/menuItems'

/**
 * nuxt app
 * ==================================================================
 */
const { $actor } = useNuxtApp()

/**
 * nuxt runtime config
 * ==================================================================
 */
const config = useRuntimeConfig()

/**
 * vuetify
 * ==================================================================
 */
const { lgAndUp } = useDisplay()
const vuetifyTheme = useTheme()

/**
 * state
 * ==================================================================
 */
const capturedError = ref<Nullable<Error>>(null)
const drawer = ref(lgAndUp)
const renderError = ref(false)

/**
 * stores
 * ==================================================================
 */
const courseMaterialsStore = useCourseMaterialsStore()

const enrollmentStore = useEnrollmentStore()

const globalStore = useGlobalStore()
const {
  isLoading,
  showPasswordSetDialog,
  showStudentReviewDialog
} = storeToRefs(globalStore)

const noticeStore = useNoticeStore()
const {
  headsUpNotice,
  newNotice,
  showHeadsUpNoticeSnackbar,
  showNewNoticeSnackbar
} = storeToRefs(noticeStore)

const organizationStore = useOrganizationStore()
const { organizationInfo: company } = storeToRefs(organizationStore)

const reviewStore = useReviewStore()
const { reviews } = storeToRefs(reviewStore)

const trialStore = useTrialStore()
const {
  isActiveTrial,
  isExpiredTrial,
  activeTrialDays
} = storeToRefs(trialStore)

const sessionStore = useSessionStore()
const { liveSessions } = storeToRefs(sessionStore)

const studyAreaStore = useStudyAreaStore()

const subjectStore = useSubjectStore()

/**
 * composables
 * ==================================================================
 */
const { isMobile } = useDevice()
const { doHandleError } = useErrorHandler()
const { menuItems } = useMenuItems()

/**
 * computed
 * ==================================================================
 */
// actor
const actor = computed(() => {
  return $actor.core.actor.value as Student
})

const actorId = computed(() => {
  return actor.value?.id || null
})

// auth
const isLoggedIn = computed(() => {
  return Boolean($actor.core.isLoggedIn.value && actor.value)
})

// styles
const mainStyles = computed(() => {
  return {
    background: `${vuetifyTheme.current.value.colors.backgroundgrey}`,
    height: '100%'
  }
})

const containerClasses = computed(() => {
  return isLoggedIn.value
    ? 'container mx-auto pt-8'
    : 'container pa-0 d-flex align-center justify-center'
})

// sessions
const liveSession = computed(() => {
  if (!liveSessions.value.length) {
    return
  }
  return liveSessions.value[0]
})

// reviews
const activeReview = computed(() => {
  return reviews.value[0]
})

const reviewCount = computed(() => {
  return reviews.value.length
})

const shouldShowStudentReviewDialog = computed(() => {
  const showStudentReviewDialogCookie =
    $actor.core.storage.getCookie(`reviewLater-${actorId.value}`)?.value

  return Boolean(
    actorId.value &&
    reviewCount.value &&
    !$actor.core.spoofing.value &&
    !showPasswordSetDialog.value &&
    !showStudentReviewDialogCookie
  )
})

const hasDismissedTrialForever = computed(() => {
  return Boolean(
    $actor.core.storage.getUniversal(
      `hideTrialExpiredAlertForever-${actorId.value}`
    )
  )
})

/**
 * watchers
 * ==================================================================
 */
watch(actorId, () => {
  if (shouldShowStudentReviewDialog.value) {
    toggleStudentReviewDialog(true)
  }
})

watch(reviewCount, () => {
  if (shouldShowStudentReviewDialog.value) {
    toggleStudentReviewDialog(true)
  }
})

watch(showPasswordSetDialog, () => {
  if (!showPasswordSetDialog.value && shouldShowStudentReviewDialog.value) {
    toggleStudentReviewDialog(true)
  }
})

/**
 * methods
 * ==================================================================
 */
// student reviews
async function doFetchReviews () {
  try {
    await reviewStore.index({
      params: {
        completed: false,
        include: 'course.brand,course.subject,employee'
      }
    })
  } catch (error) {
    doHandleError(error as FetchError)
  }
}
async function doHandleSubmitReview () {
  // close dialog
  toggleStudentReviewDialog(false)
  // refresh review data
  await doFetchReviews()
  // reopen dialog if there are still pending reviews
  if (shouldShowStudentReviewDialog.value) {
    toggleStudentReviewDialog(true)
  }
}

// navigation drawer
function doToggleDrawer () {
  drawer.value = !drawer.value
}

// dialogs
function toggleDialogRequireSetPassword (val: boolean) {
  showPasswordSetDialog.value = val
}

function toggleStudentReviewDialog (val: boolean) {
  showStudentReviewDialog.value = val
}

// snackbars
function toggleSnackbarHeadsUpNotice (val: boolean) {
  showHeadsUpNoticeSnackbar.value = val
}

function toggleSnackbarNewNotice (val: boolean) {
  showNewNoticeSnackbar.value = val
}

/**
 * lifecycle hooks
 * ==================================================================
 */
onBeforeMount(() => {
  isLoading.value = true
})

onMounted(() => {
  // Check whether or not to show DialogRequireSetPassword upon load
  if (
    actor.value &&
    actor.value.requireSetPassword &&
    !$actor.core.spoofing.value
  ) {
    toggleDialogRequireSetPassword(true)
  }

  // Conditionally open DialogStudentReview
  if (shouldShowStudentReviewDialog.value) {
    toggleStudentReviewDialog(true)
  }

  // set loading false to hide skeleton-loaders
  isLoading.value = false
})

/**
 * data fetching
 * ==================================================================
 */
await useLazyAsyncData('default-layout',
  async () => {
    try {
      if (!isLoggedIn.value) {
        return
      }
      await Promise.all([
        courseMaterialsStore.index(),
        subjectStore.index({
          params: {
            grade: actor.value?.grade
          }
        }),
        enrollmentStore.index({
          params: {
            active: true,
            include: 'brand,course,tutors'
          }
        }),
        studyAreaStore.index({
          params: {
            studentId: actorId.value,
            archived: false,
            include: 'subject'
          }
        }),
        sessionStore.index({
          params: {
            attending: true,
            include: 'supervisor,zoom_meeting,place,course,subject',
            language: 'en',
            per: 10,
            schedule: 'live'
          }
        },
        'live'
        ),
        doFetchReviews()
      ])
    } catch (errorResponse) {
      doHandleError(errorResponse as FetchError)
    }
  }
)

/**
 * lifecycle hooks
 * ==================================================================
 */
onErrorCaptured((
  err: Error,
  _instance: ComponentPublicInstance | null,
  info: string
) => {
  doHandleError(err)
  capturedError.value = err
  renderError.value = info === 'render'
  return false
})
</script>

<template>
  <v-app>
    <div
      v-if="isLoggedIn"
      style="height: 100%;"
    >
      <div
        v-if="isLoading"
        class="d-flex"
        style="height: 100%;"
      >
        <div
          v-if="!isMobile"
          class="d-flex flex-column"
          style="width: 256px; border-right: 0.75px solid #E4E4E4;"
        >
          <v-skeleton-loader
            v-for="i in 6"
            :key="`loader-${i}`"
            type="list-item"
            width="256px"
            height="56px"
            style="border-right: 0.75px solid #E4E4E4; border-bottom: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
          />
        </div>
        <div
          class="d-flex flex-column"
          style="width: 100%;"
        >
          <v-skeleton-loader
            v-if="isLoading"
            type="list-item"
            width="100%"
            height="56px"
            style="border-bottom: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
          />
          <v-skeleton-loader
            v-if="isLoading"
            width="100%"
            height="calc(100vh - 126px)"
            style="border-radius: 0px;"
          />
          <v-skeleton-loader
            v-if="isLoading"
            type="list-item"
            width="100%"
            height="70px"
            style="border-top: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
          />
        </div>
      </div>
      <template v-if="!isLoading">
        <lazy-r-navigation-drawer
          :actor="actor"
          :app-stage="config.public.appStage"
          :app-version="config.public.appVersion"
          :drawer="drawer"
          :menu-items="menuItems"
          :settings="config"
          @toggle-drawer="doToggleDrawer"
        >
          <template #bottom>
            <div
              v-if="isActiveTrial || (isExpiredTrial && !hasDismissedTrialForever)"
              class="mt-auto pa-2"
            >
              <ChipTrial
                :active-trial-days="activeTrialDays"
                :is-active-trial="isActiveTrial"
                :is-expired-trial="isExpiredTrial"
              />
            </div>
          </template>
        </lazy-r-navigation-drawer>
        <client-only>
          <LazyAppBar @toggle-drawer="doToggleDrawer" />
        </client-only>
        <lazy-r-snackbar-heads-up-notice
          v-if="headsUpNotice"
          :show="showHeadsUpNoticeSnackbar"
          :notice="headsUpNotice"
          @toggle-heads-up-snackbar="toggleSnackbarHeadsUpNotice"
        />
        <lazy-r-snackbar-new-notice
          v-if="newNotice"
          :show="showNewNoticeSnackbar"
          :notice="newNotice"
          @toggle-new-notice-snackbar="toggleSnackbarNewNotice"
        />
        <v-main
          :style="mainStyles"
          class="mb-4"
        >
          <LazyBannerLiveSession
            v-if="liveSession"
            :session="liveSession"
          />
          <v-container :class="containerClasses">
            <slot v-if="!isLoading && !renderError" />
            <r-error-display
              v-else-if="renderError && capturedError"
              :error="capturedError"
            />
          </v-container>
        </v-main>
        <client-only>
          <r-footer
            :app="isLoggedIn"
            :company="company"
            :logged-in="isLoggedIn"
            :settings="config"
          />
        </client-only>
        <LazyDialogRequireSetPassword
          v-if="showPasswordSetDialog"
          :show="showPasswordSetDialog"
          @toggle-dialog="toggleDialogRequireSetPassword(false)"
        />
        <LazyDialogStudentReview
          v-if="showStudentReviewDialog && activeReview && actorId"
          :review="activeReview"
          :show="showStudentReviewDialog"
          :student-id="actorId"
          @submit-review="doHandleSubmitReview()"
          @toggle-dialog="toggleStudentReviewDialog(false)"
        />
      </template>
    </div>
  </v-app>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.container {
  height: 100%;
}

@media (min-width: 1500px) {
  .container {
    width: 70vw;
  }
}

@media (min-width: 600px) and (max-width: 1500px) {
  .container {
    width: 75vw;
  }
}

@media (max-width: 600px) {
  .container {
    width: 95vw;
  }
}
</style>
